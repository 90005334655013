import type { NextPage } from 'next';
import Layout from '../features/layout';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

const Home: NextPage = () => {
    const router = useRouter();
    const {status} = useSession();
    
    useEffect(() => {
        if (status === 'unauthenticated') {
            router.push('/login');
        }
    }, [status]);
    
    return <>
        <Layout/>
    </>;
};

export default Home;
